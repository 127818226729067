// 北向资金流、南向资金流图表页面
<template>
  <div>
    <Ns />
    <Sn />
  </div>
</template>

<script>
import Ns from '@/components/market/ns.vue';
import Sn from '@/components/market/sn.vue';

export default {
  components: {
    Ns,
    Sn
  }
}
</script>

<template>
  <div class="box">
    <div class="title">北向资金</div>
    <div class="main-echarts" ref="mainCharts"></div>
    <div class="table_box">
      <table>
        <tr>
          <td />
          <td>当日净流入 (亿元)</td>
          <td>当日余额 (亿元)</td>
        </tr>
        <tr>
          <td class="flexItem">
            <i class="type" style="background-color: #a2d2ff" />
            沪股通
          </td>
          <td>
            <span :class="lastDataList[1] >= 0 ? 'up' : 'down'">
              {{ (lastDataList[1] / 10000).toFixed(2) }}
            </span>
          </td>
          <td>
            <span :class="lastDataList[2] >= 0 ? 'up' : 'down'">
              {{ (lastDataList[2] / 10000).toFixed(2) }}
            </span>
          </td>
        </tr>
        <tr>
          <td class="flexItem">
            <i class="type" style="background-color: #eb5405" />
            深股通
          </td>
          <td>
            <span :class="lastDataList[3] >= 0 ? 'up' : 'down'">
              {{ (lastDataList[3] / 10000).toFixed(2) }}
            </span>
          </td>
          <td>
            <span :class="lastDataList[4] >= 0 ? 'up' : 'down'">
              {{ (lastDataList[4] / 10000).toFixed(2) }}
            </span>
          </td>
        </tr>
        <tr>
          <td class="flexItem">
            <i class="type" style="background-color: #ab870f" />
            北向资金
          </td>
          <td>
            <span :class="lastDataList[5] >= 0 ? 'up' : 'down'">
              {{ (lastDataList[5] / 10000).toFixed(2) }}
            </span>
          </td>
          <td />
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

import { timeData1 } from "@/util";

export default {
  name: "marketS2N",
  props: {
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chartEL: null,
      myChart: null,
      minVal: null,
      option: {},
      loading: false,
      timeData1,
      lastDataList: [0, 0, 0, 0, 0, 0],
    };
  },
  watch: {},
  computed: {
    defaultColor() {
      return this.darkMode ? "rgba(255,255,255,0.6)" : "#ccc";
    },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.myChart.clear();
  },
  methods: {
    init() {
      this.chartEL = this.$refs.mainCharts;
      this.myChart = echarts.init(this.chartEL);
      this.option = {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          top: 55,
          bottom: 30,
          left: 45,
          right: 25,
        },
        xAxis: {
          type: "category",
          data: this.timeData1,
          axisLabel: {
            interval: this.fmtVal,
          },
          axisLine: {
            onZero: false,
          },
        },
        yAxis: {
          type: "value",
          name: "亿元",
          scale: true,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#efefef",
            },
          },
          data: [],
        },
        series: [
          {
            type: "line",
            data: [],
          },
        ],
      };
      this.getData();
    },
    getData() {
      this.loading = true;
      let url = `https://push2.eastmoney.com/api/qt/kamt.rtmin/get?fields1=f1,f2,f3,f4&fields2=f51,f52,f53,f54,f55,f56&?r=${new Date().getTime()}`;
      this.$axios.get(url).then((res) => {
        this.loading = false;
        let data = res.data.data;

        let dataList = data.s2n;
        let data1 = [];
        let data3 = [];
        let data5 = [];

        if (dataList) {
          dataList.forEach((el) => {
            let arr = el.split(",");

            if (arr[1] != "-") {
              this.lastDataList = arr;
            }

            data1.push((arr[1] / 10000).toFixed(4));
            data3.push((arr[3] / 10000).toFixed(4));
            data5.push((arr[5] / 10000).toFixed(4));
          });
          this.option.legend = {
            show: true,
          };
          this.option.color = ["#ab870f", "#a2d2ff", "#eb5405"];
          this.option.series = [
            {
              type: "line",
              name: "北向资金",
              data: data5,
              symbol: "none",
            },
            {
              type: "line",
              name: "沪股通",
              data: data1,
              symbol: "none",
            },
            {
              type: "line",
              name: "深股通",
              data: data3,
              symbol: "none",
            },
          ];
          this.myChart.setOption(this.option);
        }
      });
    },
    fmtVal(ind, val) {
      let arr = ["09:00", "10:30", "12:00", "14:35", "16:10"];
      if (arr.indexOf(val) != -1) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.flexItem {
  display: flex;
  align-items: center;
}
.box {
  width: 100%;
  height: 100%;
  font-size: 16px;
  .title {
    text-align: center;
    padding: 10px 0;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #efefef;
    background-color: #efefef;
  }
  .main-echarts {
    width: 100%;
    height: 260px;
    margin-top: 10px;
  }
  .chart-list {
    text-align: left;
    margin: 0 auto;
    width: 90%;
  }
  .table_box {
    padding: 16px;
    table {
      width: 100%;
      font-size: 14px;
      border-collapse: collapse;
      td {
        border: 1px solid #f0f0f0;
        padding: 8px;
      }
    }
  }
  li {
    line-height: 18px;
  }
  .green {
    color: #4eb61b;
  }
  .red {
    color: #f56c6c;
  }
  .total-con {
    width: 100%;
  }
  .item {
    padding: 4px 0;
  }
  .type {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 10px;
  }
}
</style>
